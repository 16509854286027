import styled, { css } from 'styled-components';
import config from '../config';

const JobOffersLayout = css`
  margin-bottom: 9.6rem;
  @media (max-width: ${config.resolutions.mobileL}) {
    margin-bottom: 8rem;
  }
`;

const OfferSection = styled.section`
  background-color: ${config.palette.white};
  border-radius: 3px;
  box-shadow: 0px 2px 2px 2px rgba(30, 36, 52, 0.1);
  margin-top: 2rem;
  padding: 3rem;

  .categories {
    margin-top: 2rem;
  }

  .category {
    color: ${config.palette.darkBlueTransparent('0.6')};
  }

  @media (max-width: ${config.resolutions.mobileL}) {
    padding: 2rem;
  }
`;

const JobTitle = styled.a`
  color: ${config.palette.darkBlue};
  font-weight: bold;
  font-size: 2rem;
  line-height: 160%;
  border-bottom: 0.1rem solid ${config.palette.darkBlueTransparent('0.2')};
  text-decoration: none;
`;

export { OfferSection, JobTitle, JobOffersLayout };
