import React from 'react';
import _ from 'lodash';
import { Title1 } from '../atoms/headers';
import { Text4 } from '../atoms/body';
import Layout from '../atoms/layout';
import { JobOffersLayout, OfferSection, JobTitle } from './styles';
import JobOffersProps from '../interfaces/JobOffers';
import { withTranslation } from 'react-i18next';

const JobOffers: React.SFC<any> = (props: JobOffersProps): JSX.Element => {
  const { offers, t } = props;

  return (
    <Layout extend={JobOffersLayout}>
      <Title1>{t('work-with-us:workWithUs.offersListTitle')}</Title1>
      {offers.map(offer => (
        <OfferSection>
          <JobTitle href={offer.hostedUrl}>{offer.text}</JobTitle>
          <article className="categories">
            {_.toPairs(offer.categories).map(([key, value]) => (
              <Text4 as="p" className="category">{`${_.capitalize(
                key
              )}: ${value}`}</Text4>
            ))}
          </article>
        </OfferSection>
      ))}
      {_.isEmpty(offers) && (
        <OfferSection>
          <JobTitle href="">
            {t('work-with-us:workWithUs.noJobsTitle')}
          </JobTitle>
        </OfferSection>
      )}
    </Layout>
  );
};

export default withTranslation()(JobOffers);
