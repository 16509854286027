import React, { useState, useEffect } from 'react';
import fetch from 'node-fetch';
import _ from 'lodash';
import { graphql } from 'gatsby';

import withI18next from '../../i18n/TemplateI18n';
import Header from '../../components/Header';
import PageTitle from '../../components/PageTitle';
import Footer from '../../components/Footer';
import Props from '../../interface/template';
import JobOffers from '../../components/JobOffers';
import InternalLinks from '../../containers/InternalLinks';
import useUserLocation from '../../hooks/useUserLocation';
import Banner from '../../components/Banner';

const WorkWithUsPage: React.SFC<any> = (props: Props) => {
  const { t, pageContext } = props;
  const { pageName } = pageContext;
  const seoPage = 'team/workWithUs';
  const canonicalCode = 'workWithUs';
  const { userLocation } = useUserLocation();

  const [offers, setOffers] = useState([]);

  useEffect(() => {
    const fetchBoard = async () => {
      const leverResponse = await fetch(
        'https://api.lever.co/v0/postings/iron_hack?mode=json'
      );
      const parsedOffers = await leverResponse.json();
      setOffers(parsedOffers);
    };

    fetchBoard();
  }, []);

  return (
    <>
      {userLocation?.country === 'NL' && <Banner type="stap" />}
      <Header pageName={pageName} showBackground>
        <PageTitle
          subtitle={t('work-with-us:workWithUs.mainHeadline.description')}
          title={t('work-with-us:workWithUs.mainHeadline.title')}
        />
      </Header>
      <JobOffers offers={offers} />
      <InternalLinks />
      <Footer page={pageName} />
    </>
  );
};

export const query = graphql`
  query ($locale: String!) {
    locales: allLocale(
      filter: {
        lng: { eq: $locale }
        ns: {
          regex: "/(menu)|(work-with-us)|(seo)|(breadcrumbs)|(banner)|(navigation)/"
        }
      }
    ) {
      ...TranslationFragment
    }
  }
`;

export default withI18next()(WorkWithUsPage);
